import React from 'react';

import Carousel from 'components/Carousel/Carousel';

import { container, sub } from './OffersCarousel.module.scss';

const OffersCarousel = ({
  subtitle = null,
  title = null,
  products,
  cta = null,
  containerClass = null,
}) => {
  if (!products || products.length === 0) {
    return null;
  }
  return (
    <div
      className={`${container}${containerClass ? ' ' + containerClass : ''}`}
    >
      {subtitle ? <p className={sub}>{subtitle}</p> : null}
      {title}
      <Carousel products={products} />
      {cta}
    </div>
  );
};

export default OffersCarousel;
