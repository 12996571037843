import React from 'react';

import * as styles from './TitleBrand.module.scss';
import { mainHeaderDark } from 'styles/components/typography.module.scss';

const TitleBrand = ({ text, style = null }) => {
  return (
    <div className={styles.container}>
      <h1 className={`${mainHeaderDark} ${styles.title}`} style={style}>
        {text}
      </h1>
    </div>
  );
};

export default TitleBrand;
